<template>
  <b-card>
    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-img
            ref="previewEl"
            roundedc
            :src="server_url + logo_path"
            height="80"
          />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="$refs.refInputEl.$el.click()"
        >
          Importer Logo
        </b-button>
        <b-form-file
          ref="refInputEl"
          v-model="logoFile"
          accept=".jpg, .png, .gif"
          :hidden="true"
          plain
          @input="onFileChange"
        />

        <!-- <div class="mt-3">Selected file: {{ logoFile ? logoFile.name : "" }}</div> -->

        <!-- @input="inputImageRenderer" -->
        <!--/ upload button -->

        <!-- reset -->
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          size="sm"
          class="mb-75 mr-75"
        >
          Annuler
        </b-button>
        <!--/ reset -->
        <b-card-text
          >Extension autorisées: JPG, GIF ou PNG. Max size of 100kB</b-card-text
        >
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">
          <b-form-group label="Organisation" label-for="corporationName">
            <b-form-input
              v-model="corporation_name"
              placeholder="Nom complet de votre Organisation"
              name="corporation_name"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Abréviation" label-for="corporationNameMini">
            <b-form-input
              v-model="corporation_name_mini"
              name="corporation_name_mini"
              placeholder="Sigle ou Abreviation de l'Ets."
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Ville" label-for="town">
            <b-form-input
              v-model="town"
              name="town"
              placeholder="Siege Social "
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Directeur/Coordinateur(trice)" label-for="ceo">
            <b-form-input
              v-model="ceo"
              name="ceo"
              placeholder="Responsable Principale"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="E-mail" label-for="account-e-mail">
            <b-form-input v-model="email" name="email" placeholder="Email" />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Téléphone" label-for="phone">
            <b-form-input
              v-model="phone"
              name="phone"
              placeholder="N° Téléphone de l'Ets."
            />
          </b-form-group>
        </b-col>

        <!-- alert -->
        <!-- <b-col cols="12" class="mt-75">
          <b-alert show variant="warning" class="mb-50">
            <h4 class="alert-heading">
              Your email is not confirmed. Please check your inbox.
            </h4>
            <div class="alert-body">
              <b-link class="alert-link"> Resend confirmation </b-link>
            </div>
          </b-alert>
        </b-col> -->
        <!--/ alert -->

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click.prevent="postCorporationSettingGeneralForm"
          >
            Enregistrer
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
            @click.prevent="resetForm"
          >
            Annuler
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import { $themeConfig } from "@themeConfig";
import axios from "axios";
import router from "@/router";

const userData = JSON.parse(localStorage.getItem("userData"));

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      sku_corporation: userData.sku_corporation,
      optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
      corporation_name: JSON.parse(JSON.stringify(this.generalData))
        .corporation_name,
      corporation_name_mini: JSON.parse(JSON.stringify(this.generalData))
        .corporation_name_mini,
      logoFile: null,
      profileFile: null,
      logo_path: JSON.parse(JSON.stringify(this.generalData)).logo_url,
      ceo: JSON.parse(JSON.stringify(this.generalData)).ceo,
      phone: JSON.parse(JSON.stringify(this.generalData)).phone,
      email: JSON.parse(JSON.stringify(this.generalData)).email,
      country: JSON.parse(JSON.stringify(this.generalData)).country,
      state: JSON.parse(JSON.stringify(this.generalData)).state, 
      town: JSON.parse(JSON.stringify(this.generalData)).town,
      adresse: JSON.parse(JSON.stringify(this.generalData)).adresse,
      employee_capacity: JSON.parse(JSON.stringify(this.generalData))
        .employee_capacity,
      errors: [],
      status: "",
      imageData: null,
      url: null,
      server_url: $themeConfig.app.remote_server_url,
    };
  },
  methods: {
    async onFileChange(e) {
      const file = this.logoFile;

      let formData = new FormData();
      formData.append("logo", file);
      formData.append("sku_corporation", this.sku_corporation);

      myApi
        .post("upload_corporation_logo")
        .then((response) => {
          if (response.status == 200) {
            // preview image (refresh)
            this.logo_path = response.data.full_url;
            // local session update
            optionsLocal.logo_path = this.logo_path;
          } else {
            this.errors.push(response.message);
          }
        })
        .catch((error) => {
          
        });
    },
    async postCorporationSettingGeneralForm() {
      // for the web app
      let formData = new FormData();
      formData.append("sku_corporation", this.sku_corporation);
      formData.append("corporation_name", this.corporation_name);
      formData.append("corporation_name_mini", this.corporation_name_mini);
      formData.append("ceo", this.ceo);
      formData.append("phone", this.phone);
      formData.append("email", this.email);
      formData.append("country", this.country);
      formData.append("state", this.state);
      formData.append("town", this.town);
      formData.append("adresse", this.adresse);
      formData.append("employee_capacity", this.employee_capacity);

      await myApi
        .post("update_corporation_infos", formData)
        .then((response) => {
          console.log(response);
          if (response.status == 200) {
            this.$swal({
              title: "Fin de l'opération",
              text: response.data.message,
              icon: "success",
              timer: 10000,
              showCancelButton: true,
              confirmButtonText: "Continuer",
              cancelButtonText: "Annuler",
              customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-outline-warning ml-1",
              },
              buttonsStyling: false,
            });
          } else {
            this.$swal({
              title: "Erreur Survenu",
              text: response.data.message,
              icon: "error",
              timer: 10000,
              showCancelButton: false,
              confirmButtonText: "Continuer",
              cancelButtonText: "Annuler",
              customClass: {
                confirmButton: "btn btn-warning",
                cancelButton: "btn btn-outline-error ml-1",
              },
              buttonsStyling: false,
            });
          }
        })
        .catch((error) => {
          this.$swal({
            title: "Erreur Survenu",
            text: error.message,
            icon: "error",
            timer: 10000,
            showCancelButton: false,
            confirmButtonText: "Continuer",
            cancelButtonText: "Annuler",
            customClass: {
              confirmButton: "btn btn-warning",
              cancelButton: "btn btn-outline-error ml-1",
            },
            buttonsStyling: false,
          });
        });
    },
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData));
    },
  },
  setup() {
    const refInputEl = ref(null);
    const previewEl = ref(null);
    const logo_path = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl);

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
      logo_path,
    };
  },
};
</script>
